<template>
 <div id="plan1" class="plan" v-title :data-title="title">
   <page-template :carousel-list="carouselList"
                  type="plan"
                  :plan1-active="plan1Active"
                  :plan1-details="plan1Details"
                  :plan2-title="plan2Title"
                  :plan2-list="plan2List"
                  :plan3-list="plan3List"
                  />
 </div>
</template>

<script>
import PageTemplate from '../components/template.vue'
import Public from "@/assets/js/public";
export default {
  name: 'Plan5',
  components: {
    PageTemplate
  },
  mixins: [],
  data () {
    return {
      title: this.$route.query.title || '产品服务',
      // 轮播列表
      carouselList: [
        { img: require('@/assets/images/0966.jpg'), txt: '智能运营服务平台', txt2: '大屏观态势', txt3: '，中屏管调度，小屏做执行', jumpUrl: 'btn'},
      ],
      plan1Active: Public.plan5().routerName,
      // 当前方案概述详情
      plan1Details: Public.plan5(),
      plan2Title: {
        title: '产品亮点', txt: 'Product Highlights'
      },
      // 我们的经验
      plan2List: [
        {img: require('@/assets/images/0214.fw.png'), title: '全流程闭环运维', txt: '资源管理、设备监控和巡检、事件管理、问题跟踪管理、服务请求管理、变更管理、知识库管理'},
        {img: require('@/assets/images/02155.fw.png'), title: '多厂商设备统一管理', txt: '集成各厂商的网管系统，，自研网络设备监控系统进行纳管。'},
        {img: require('@/assets/images/02156.fw.png'), title: '对接工单系统', txt: '平台对接工单系统，完成网络故障处理闭环'},
        {img: require('@/assets/images/02157.fw.png'), title: '运维服务一图可视', txt: '大屏（观态势）、中屏（管调度）和小屏（做执行）、立体的呈现整个网络运维服务运转情况。'},
      ],
      // 服务案例
      plan3List: [
        {img: require('@/assets/images/31.fw.png'), title: '河南大学', txt: '国家“双一流”建设高校，入选国家“111计划”、中西部高校基础能力建设工程'},
        {img: require('@/assets/images/30.fw.png'), title: '河南师范大学', txt: '是国家中西部高等教育振兴计划支持高校、国家“111计划”实施高校'},
        {img: require('@/assets/images/32.fw.png'), title: '新乡学院', txt: '是一所经教育部批准建立的公办全日制普通本科院校。学校始建于1949年'},
        {img: require('@/assets/images/school/kf.jpg'), title: '河南开放大学', txt: '前身是创办于1979年的河南广播电视大学，2020年11月正式更名为河南开放大学'},
        {img: require('@/assets/images/school/kjxy.jpg'), title: '河南科技学院', txt: '河南科技学院是一所省属普通本科院校。学校始建于1939年'},
        {img: require('@/assets/images/school/xg.jpg'), title: '新乡工程学院', txt: '是一所全日制民办普通本科高校。前身是创建于2003年的河南职业技术师范学院新科学院'},
        {img: require('@/assets/images/school/zxk.jpg'), title: '郑州信息科技职业学院', txt: '2002年由河南省政府批准成立的一所专科层次的全日制普通高职院校'},
        {img: require('@/assets/images/school/zs.jpg'), title: '周口师范学院', txt: '学校始建于1973年，2002年经教育部批准升格为本科院校。学校现占地1600余亩'},
        {img: require('@/assets/images/school/zw.jpg'), title: '周口文理职业学院', txt: '是2021年经河南省政府批准成立，教育部备案的公办专科层次全日制普通高校'},
        {img: require('@/assets/images/school/ch.jpg'), title: '河南测绘职业学院', txt: '是教育部备案的全国唯一一所测绘类公办专科层次的全日制普通高等职业院校'},
      ]
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {

  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
