<template>
 <div>
  <!-- 页面导航 -->
  <menu-components/>

  <!-- 页面主体 -->
  <main class="main">
   <section class="home-banner">
    <el-carousel :autoplay="false" ref="carousel" :interval="5000" arrow="always" height="6.02rem">
     <el-carousel-item v-for="(item, index) in carouselList" :key="index">
      <div class="home-banner-img" :style="{backgroundImage: 'url('+ item.img + ')'}" style="padding-top:2rem">
       <div class="container">
        <h2>{{item.txt}}</h2>
        <h1>{{item.txt5}}<span>{{item.txt2}}</span>{{item.txt3}}</h1>
        <p>{{item.txt4}}</p>
        <el-button v-if="item.jumpUrl" @click="showDialog" class="other-p-bt">解决方案&nbsp;  <i class="el-icon-arrow-right"></i></el-button>
       </div>

      </div>
     </el-carousel-item>
    </el-carousel>
   </section>

  <!-- 方案概述 -->
   <section class="plan-1">
    <div class="container">
      <ul class="flex align-center">
       <li v-for="(item, index) in plan1List" class="flex align-center justify-center wrap"
           :class="plan1ActiveModel == item.routerName ? 'active' : ''"
           @click="jumpPlan(item)"
           :key="index">
        <h2>{{item.name}}</h2>
        <i v-if="plan1ActiveModel == item.routerName" class="el-icon-arrow-down"></i>
       </li>
      </ul>
     <el-row :gutter="150"  class="plan-1-b">
      <el-col :sm="24" :md="12">
       <div class="plan-1-b-l plan-title">
        <template v-if="type == 'plan'">
         <div>Program Overview</div>
         <h2>方案概述</h2>
        </template>
        <template v-else>
         <div>Product Description</div>
         <h2>产品介绍</h2>
        </template>
        <p>{{plan1Details.txt}}</p>
        <p>{{plan1Details.txt1}}</p>
        <el-button @click="showDialog">{{type == 'plan' ? '获取详细方案' : '申请试用'}}&nbsp;  <i class="el-icon-arrow-right"></i></el-button>
       </div>
      </el-col>
      <el-col :sm="24" :md="12">
       <div class="plan-1-b-r-d " v-if="plan1Details.img">
        <img :src="plan1Details.img" alt="">
       </div>
       <div class="plan-1-b-r-l " :style="{marginBottom: plan2Title.title == '建设目标' ? '-.5rem' : ''}" v-if="plan1Details.imgL">
        <img :src="plan1Details.imgL" alt="">
       </div>
      </el-col>
     </el-row>

    </div>
   </section>

  <!-- 我们的经验-->
   <section class="plan-2" :style="{marginTop: plan2Title.title == '建设目标' ? '-.2rem' : ''}">
    <div class="container">
      <div class="plan-title">
       <div>{{plan2Title.txt}}</div>
       <h2>{{plan2Title.title}}</h2>
      </div>
     <el-row :gutter="20"  class="plan-2-b">
      <el-col v-for="(item, index) in plan2List" class="plan-2-b-l" :key="index" :xs="24" :md="6">
       <div class="plan-2-b-i ">
        <img :src="item.img" alt="">
        <div>{{item.title}}</div>
        <p>{{item.txt}}</p>
       </div>
      </el-col>
     </el-row>
     </div>
   </section>

  <!-- 数字化校园应用清单 -->
   <section class="plan-4" v-if="plan4Show">
    <div class="container">
     <div class="plan-title">
      <div>Digital Campus</div>
      <h2>数字校园应用清单</h2>
     </div>
     <div class="plan-4-1 flex align-center">
      <div v-for="(item, index) in plan4Title" :key="index"
           @click="plan4TitleActive = index"
           :class="plan4TitleActive == index ? 'active' : ''">{{item.name}}</div>
     </div>
     <ul class="flex align-center justify-between wrap plan-4-2">
      <li v-for="(item, index) in plan4List" :key="index" class="flex align-center">
       <img src="@/assets/images/27.fw.png" alt="">
       <div>
        <h2>{{item.title}}</h2>
        <p>{{item.txt}}</p>
       </div>
      </li>
     </ul>
    </div>
   </section>

  <!-- 服务案例 -->
   <section class="plan-3">
    <div class="container">
     <div class="plan-title">
      <div>Operation Case</div>
      <h2>服务案例</h2>
     </div>
     <div class="vue-slick-carousel-box">
      <vue-slick-carousel v-bind="settings" class="vue-slick-carousel" ref="vueSlickCarousel">
       <div v-for="(item, index) in plan3List" :key="index">
        <div class="plan-3-l" >
         <img :src="item.img" alt="">
         <h2>{{item.title}}</h2>
         <p>{{item.txt}}</p>
        </div>
       </div>
      </vue-slick-carousel>
      <!-- 自定义左右指示器 -->
      <template v-if="plan3List.length > 3">
       <img class="vue-slick-carousel-arrow vue-slick-carousel-left" src="@/assets/images/img5.png" @click="prevClick" alt="">
       <img class="vue-slick-carousel-arrow vue-slick-carousel-right" :src="customNextArrow"
            @mouseenter="handleMouseEnter"
            @mouseleave="handleMouseLeave"
            @click="nextClick" alt="">
      </template>

     </div>
    </div>
   </section>
  </main>

  <!-- 页面底部 -->
  <footer-components style="margin-top:0;"/>

  <!-- 获取方案 -->
  <dialog-template :visible.sync="dialogShow"/>
 </div>
</template>

<script>
import MenuComponents from "@/components/Menu/index.vue";
import FooterComponents from "@/components/Footer/index.vue";
import DialogTemplate from "@/components/Dialog/index.vue";
import Public from "@/assets/js/public";
export default {
  name: 'PageTemplate',
  components: {
   DialogTemplate,
   MenuComponents,
   FooterComponents
  },
  props: {
   type: {
    type: String,
    default: 'plan'
   },
    // 轮播内容
   carouselList: Array,
   // 当前方案下标
   plan1Active: String,
   // 当前方案详情
   plan1Details: Object,
   // 我的经验
   plan2List: Array,
   // 我的经验标题
   plan2Title: Object,
   // 服务案例
   plan3List: Array,
   // 我的经验标题
   plan4Show: {
    type: Boolean,
    default: false
   }
  },
  mixins: [],
  data () {
    return {
     // 当前方案下标
     plan1ActiveModel: this.plan1Active,
     // 应用清单
     plan4Title: [
       {name: '智慧校园管理', value: 1},
       {name: '在校生管理', value: 2},
     ],
     plan4TitleActive: 0,
     settings: {
      "dots": false, // 启用点指示器
      'arrows': false,// 展示箭头
      "infinite": false, // 是否无限循环展示内容
      "speed": 500,
      "slidesToShow": 3, // 同时显示的项目数量
      "slidesToScroll": 3, // 每次滚动的项目数量
      "initialSlide": 0
     },
     arrowOption: {
      currentSlide: 1,
      slideCount: 2
     },
     customNextArrow: require('@/assets/images/img4.png'),
     dialogShow: false
    };
  },
 computed: {
  plan1List() { // 方案概述
   let list = []
   if (this.type == 'product') {
    list = Public.product()
   } else {
    list = Public.plan();
   }
   return list;
  },
  // 数字校园应用清单
  plan4List() {
   let list = []
   if (this.plan4TitleActive == 0) {
    list = [
     {title: '物联网能耗管控、宿管工单报修系统', txt: '需求部门：后勤处'},
     {title: '办公用房管理', txt: '需求部门：房管科'},
     {title: '空闲教室管理、教师工作量管理', txt: '需求部门：教务处'},
     {title: '体检预约、医保补助管理、外诊报销', txt: '需求部门：校医院'},
     {title: '党、工费管理', txt: '需求部门：工会、党组织'},
     {title: '值班管理、 全流程考试管理、校外培训管理', txt: '需求部门：各单位'},
     {title: '重点工作督导、重大活动填报管理', txt: '需求部门：校办、党办'},
     {title: '弱电管网可视化、网络报修工单系统、网络运维可视化', txt: '需求部门：网络中心'},
     {title: '科研成果上报管理', txt: '需求部门：科研处'},
     {title: '校园团学管理', txt: '需求部门：校团委'},
     {title: '智慧迎新迎新、招生宣传', txt: '需求部门：招生办 学生处'},
     {title: '数字素养培训平台', txt: '需求部门：人事处'},
    ]
   } else {
    list = [
     {title: '新生身份核验系统', txt: '需求部门：学生处'},
     {title: '安全教育系统', txt: '需求部门：学生处'},
     {title: '智慧迎新系统', txt: '需求部门：学生处'},
     {title: '智慧招生宣传', txt: '需求部门：招办'},
     {title: '离校管理系统', txt: '需求部门：学生处'},
     {title: '云书院（辅导员助手）', txt: '需求部门：学生处'},
    ];
   }
   return list;
  }
 },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {
   prevClick() {
    this.$refs.vueSlickCarousel.prev()
   },
   nextClick () {
    this.$refs.vueSlickCarousel.next()
   },
   handleMouseEnter() {
    this.customNextArrow = require('@/assets/images/42.fw.png')
   },
   handleMouseLeave() {
    this.customNextArrow = require('@/assets/images/img4.png')
   },
   /* 跳转方案 */
   jumpPlan (item) {
    this.plan1ActiveModel = item.routerName;
    this.$router.push({name: item.routerName, query: {title: item.name}})
   },
   showDialog() {
    this.dialogShow = true
   }
  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
